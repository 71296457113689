import axios from 'axios';
import { handleError } from '../utils/ErrorHandler';
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiClient = axios.create({
    baseURL: BASE_URL,
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        const csrfToken = localStorage.getItem('csrfToken');

        if (token && csrfToken) {
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['X-CSRF-Token'] = csrfToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const fetchLatestBodyStats = async (): Promise<any[]> => {
    try {
        const res = await apiClient.get('/bodystats/last');
        return res.data;
    } catch (error: any) {
        if (error.code === 'ERR_BAD_REQUEST') {
            toast.warn('No data found');
        } else {
            handleError(error);
        }

        return [];
    }
};

export const saveBodyStats = async (data: any): Promise<any> => {
    try {
        const res = await apiClient.get('/bodystats/add', data);
        return res.data;
    } catch (error) {
        handleError(error);
        return null;
    }
};
