import axios, { AxiosError } from 'axios';
import { handleError } from '../utils/ErrorHandler';
import { toast } from 'react-toastify';

interface APIError {
    error: string;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiClient = axios.create({
    baseURL: BASE_URL,
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        const csrfToken = localStorage.getItem('csrfToken');

        if (token && csrfToken) {
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['X-CSRF-Token'] = csrfToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getWorkoutGroupsApi = async () => {
    try {
        const data = await apiClient.get('/workout/group/user');
        return data;
    } catch (error: any) {
        if (error.code === 'ERR_BAD_REQUEST') {
            toast.warn('No data found');
        } else {
            handleError(error);
        }
    }
};

export const createWorkoutGroupApi = async (group: {
    name: string;
    description?: string;
}) => {
    try {
        const data = await apiClient.post('/workout/group/add', group);
        return data;
    } catch (error) {
        handleError(error);
    }
};

export const cloneWorkoutGroupApi = async (
    code: string
): Promise<{ success: boolean; message: string } | APIError> => {
    try {
        const response = await apiClient.post<{
            success: boolean;
            message: string;
        }>(`/workout/group/clone/${code}`);
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};
