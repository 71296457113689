import axios from 'axios';
import { handleError } from '../utils/ErrorHandler';
import {
    AddExerciseDto,
    ExerciseSearchResponse,
    Workout,
} from '../models/exerciseModel';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiClient = axios.create({
    baseURL: BASE_URL,
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        const csrfToken = localStorage.getItem('csrfToken');

        if (token && csrfToken) {
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['X-CSRF-Token'] = csrfToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const searchExerciseApi = async (
    name: string
): Promise<ExerciseSearchResponse> => {
    try {
        const { data } = await apiClient.get<ExerciseSearchResponse>(
            `/exercise/search/${name}`
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const addExerciseApi = async (exercise: AddExerciseDto) => {
    try {
        const response = await apiClient.post(
            `/exercise/add/private`,
            exercise
        );
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const saveWorkoutApi = async (
    groupId: number | null,
    exercises: Workout['exercises']
) => {
    try {
        const data = await apiClient.post(`/workout/add`, {
            groupId,
            exercises: exercises.map((ex) => ({
                exerciseId: ex.exercise.id,
                sets: ex.sets,
            })),
        });
        return data;
    } catch (error) {
        handleError(error);
    }
};

export const deleteWorkoutGroupApi = async (groupId: number) => {
    try {
        const data = await apiClient.delete(`/workout/group/${groupId}`);
        return data;
    } catch (error) {
        handleError(error);
    }
};

export const getLastWorkoutByGroupIdApi = async (groupId: number) => {
    try {
        const data = await apiClient.get(`/workout/group/last/${groupId}`);
        return data;
    } catch (error) {
        handleError(error);
    }
};
