import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../context/useAuth';
import { toast } from 'react-toastify';
import avatar0 from '../assets/images/profile/0.png';
import avatar1 from '../assets/images/profile/1.png';
import avatar2 from '../assets/images/profile/2.png';
import avatar3 from '../assets/images/profile/3.png';
import avatar4 from '../assets/images/profile/4.png';
import avatar5 from '../assets/images/profile/5.png';

type UpdateUserForm = {
    email?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    password: string;
    avatar?: number;
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format'),
    username: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    password: Yup.string()
        .required('Password is required')
        .min(3, 'The password must be at least 3 characters')
        .max(20, 'The password may be a maximum of 20 characters.'),
});

const UserSettingsPage: React.FC = () => {
    const { user, updateUser } = useAuth();
    const [selectedAvatar, setSelectedAvatar] = useState<number>(
        user?.avatar ?? 0
    );
    const [showPassword, setShowPassword] = useState(false);

    const avatars = [avatar0, avatar1, avatar2, avatar3, avatar4, avatar5];

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateUserForm>({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: UpdateUserForm) => {
        const response = await updateUser({ ...data, avatar: selectedAvatar });
        if (response.success) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-3xl font-bold mb-4">User Settings</h2>
            <p className="text-gray-400 mb-8">
                Here you can update your user profile.
            </p>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="bg-gray-800 p-6 rounded-lg shadow-lg"
            >
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="email"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        defaultValue={user?.email}
                        {...register('email')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.email && (
                        <p className="text-red-500 text-xs italic">
                            {errors.email.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="username"
                    >
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        defaultValue={user?.username}
                        {...register('username')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.username && (
                        <p className="text-red-500 text-xs italic">
                            {errors.username.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="firstName"
                    >
                        First Name
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        defaultValue={user?.firstName}
                        {...register('firstName')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.firstName && (
                        <p className="text-red-500 text-xs italic">
                            {errors.firstName.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="lastName"
                    >
                        Last Name
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        defaultValue={user?.lastName}
                        {...register('lastName')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.lastName && (
                        <p className="text-red-500 text-xs italic">
                            {errors.lastName.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <div>
                        <label
                            htmlFor="password"
                            className="block mb-2 text-sm font-medium text-white"
                        >
                            Password
                        </label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Password"
                                {...register('password')}
                            />
                            <button
                                type="button"
                                className={`absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 transition-opacity ${
                                    showPassword ? 'opacity-100' : 'opacity-50'
                                }`}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                👁️
                            </button>
                        </div>
                        {errors.password && (
                            <p className="text-red-400">
                                {errors.password.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-300 text-sm font-bold mb-2">
                        Choose Avatar
                    </label>
                    <div className="flex flex-wrap gap-4">
                        {avatars.map((avatar, index) => (
                            <img
                                key={index}
                                src={avatar}
                                alt={`Avatar ${index}`}
                                className={`w-16 h-16 rounded-full cursor-pointer ${
                                    selectedAvatar === index
                                        ? 'border-4 border-blue-500'
                                        : 'border-2 border-gray-600'
                                }`}
                                onClick={() => setSelectedAvatar(index)}
                            />
                        ))}
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Update Profile
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserSettingsPage;
